<template>
  <div id="vehicleListTab">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">过期状态:</span>
            <el-select
              v-model="filtrate.notExpired"
              placeholder="请选择过期状态"
              @change="getList(1)"
              clearable
            >
              <el-option label="已过期" :value="0"> </el-option>
              <el-option label="未过期" :value="1"> </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">分组:</span>
            <el-select
              v-model="filtrate.seatGroupId"
              placeholder="请选择分组"
              filterable
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item of seatGroupList"
                :key="item.seatGroupId"
                :label="item.seatGroupName"
                :value="item.seatGroupId"
              ></el-option>
            </el-select>
          </li>
          <li>
            <span class="label">套餐:</span>
            <el-select
              v-model="filtrate.priceId"
              placeholder="请选择套餐"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item of priceList"
                :key="item.priceId"
                :label="item.priceName"
                :value="item.priceId"
              ></el-option>
            </el-select>
          </li>
          <li>
            <span class="label">租赁类型:</span>
            <el-select
              v-model="filtrate.type"
              placeholder="请选择租赁类型"
              @change="getList(1)"
              clearable
            >
              <el-option label="永久" value="永久"> </el-option>
              <el-option label="第三方" value="第三方"> </el-option>
            </el-select>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addVehicle"
              type="primary"
              @click="$refs.actionDialog.show = true"
              v-if="
                buttonList.includes('parkingLotList-detail-vehicleListTab-add')
              "
              ><i class="iconfont icon-jiahao"></i> 新建车辆
            </el-button>
          </li>
          <li>
            <el-button
              class="addVehicle"
              type="primary"
              @click="$refs.importDialog.show = true"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-vehicleListTab-import'
                )
              "
              ><i class="iconfont el-icon-upload2"></i> 导入报表
            </el-button>
          </li>
          <li>
            <el-button
              class="addVehicle"
              type="primary"
              @click="exportExcel"
              :loading="exportLoading"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-vehicleListTab-export'
                )
              "
              ><i class="iconfont el-icon-download"></i> 导出报表
            </el-button>
          </li>
          <li>
            <el-button
              class="create_btn"
              type="primary"
              @click="batchEdit"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-vehicleListTab-batchEdit'
                )
              "
              :disabled="filtrate.type !== '永久'"
              ><i class="iconfont el-icon-finished"></i>
              批量编辑
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
        @selection-change="selectionChange"
      >
        <el-table-column
          v-if="
            buttonList.includes(
              'parkingLotList-detail-vehicleListTab-batchEdit'
            ) && filtrate.type === '永久'
          "
          type="selection"
          width="55"
        >
        </el-table-column>
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column prop="leaseType" label="租赁类型" align="center">
        </el-table-column>
        <el-table-column prop="priceName" label="套餐" align="center">
        </el-table-column>
        <el-table-column prop="seatGroupName" label="分组" align="center">
        </el-table-column>
        <el-table-column prop="subjectName" label="车辆归属" align="center">
        </el-table-column>
        <el-table-column prop="contacts" label="联系人" align="center">
        </el-table-column>
        <el-table-column prop="contactPhone" label="联系方式" align="center">
        </el-table-column>
        <el-table-column prop="remarks" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="alwaysOpen" label="始终入场" align="center">
        </el-table-column>
        <el-table-column
          prop="leaseTimesFormat"
          label="时间范围"
          align="center"
          width="260px"
        >
          <template slot-scope="scope">
            <p
              v-for="(time, timeIndex) of scope.row.leaseTimesFormat"
              :key="timeIndex"
              :style="{ color: time.notExpired ? 'red' : 'white' }"
            >
              {{ time.format }}
            </p>
          </template>
        </el-table-column>

        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-vehicleListTab-edit'
                ) && scope.row.leaseType !== '第三方'
              "
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="del(scope.row.leaseId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-vehicleListTab-delete'
                ) && scope.row.leaseType !== '第三方'
              "
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <action-dialog ref="actionDialog" @refreshList="getList" />
    <import-dialog ref="importDialog" @refreshList="getList" />
    <batch-edit-dialog ref="batchEditDialog" @refreshList="getList" />
  </div>
</template>
<script>
export default {
  components: {
    actionDialog: () => import("./actionDialog.vue"),
    importDialog: () => import("./importDialog.vue"),
    batchEditDialog: () => import("./batchEditDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        notExpired: "",
        type: "",
        seatGroupId: "",
        priceId: "",
      },
      table: {
        tableData: [],
        selection: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
      seatGroupList: [],
      priceList: [],
      parkingLotId: "",
      exportLoading: false,
    };
  },
  created() {
    this.parkingLotId = this.$route.params.id;
    this.getList();
    this.getSeatGroupList();
    this.getPriceList();
  },
  methods: {
    async exportExcel() {
      try {
        this.exportLoading = true;
        await this.$http.post(
          "/price/lease/list/export",
          {
            keyword: this.filtrate.keyword,
            parkingLotId: this.parkingLotId,
            notExpired:
              this.filtrate.notExpired >= 0 ? this.filtrate.notExpired : null,
            seatGroupId: this.filtrate.seatGroupId
              ? this.filtrate.seatGroupId
              : null,
            priceId: this.filtrate.priceId ? this.filtrate.priceId : null,
            type: this.filtrate.type ? this.filtrate.type : null,
          },
          {
            responseType: "blob",
            timeout: 0,
          }
        );
      } catch (err) {
        this.$message.error("导出失败");
      } finally {
        this.exportLoading = false;
      }
    },
    batchEdit() {
      const selection = this.table.selection;
      if (selection.length === 0) {
        this.$message.warning("请勾选需要操作的车辆！");
        return;
      }
      this.$refs.batchEditDialog.actionList = selection;
      this.$refs.batchEditDialog.show = true;
    },
    selectionChange(selection) {
      this.table.selection = selection;
    },
    async getSeatGroupList() {
      let res = await this.$http.get(
        `/parking/seat/group/${this.parkingLotId}/all`
      );
      this.seatGroupList = res.data;
    },
    async getPriceList() {
      let res = await this.$http.get(`/price/simple/${this.parkingLotId}/list`);
      this.priceList = res.data;
    },
    del(leaseId) {
      this.$confirm("此操作将永久删除该车辆, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/price/lease/delete/${leaseId}`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    async edit(row) {
      let res = await this.$http.get(`/price/lease/${row.leaseId}/info`);
      this.$refs.actionDialog.leaseId = res.data.leaseId;
      if (!res.data.leaseType) {
        this.$refs.actionDialog.type = "无";
      } else if (res.data.leaseType === "永久") {
        this.$refs.actionDialog.type = "免费";
      }
      this.$refs.actionDialog.actionForm = {
        plateNum: res.data.plateNum,
        subjectName: res.data.subjectName,
        contacts: res.data.contacts,
        contactPhone: res.data.contactPhone,
        remarks: res.data.remarks,
        alwaysOpen: res.data.alwaysOpen === "是" ? 1 : 0,
        priceId: res.data.priceId,
        leaseType: res.data.leaseType,
        seatGroupId: res.data.seatGroupId,
      };
      this.$refs.actionDialog.leaseTimes = res.data.leaseTimes.map((item) => {
        return [
          this.$moment(item.startTime).format(),
          this.$moment(item.endTime).format(),
        ];
      });
      this.$refs.actionDialog.default_whiteList = res.data.whiteList;
      this.$refs.actionDialog.default_blackList = res.data.blackList;
      this.$refs.actionDialog.show = true;
    },
    reset() {
      this.filtrate.keyword = "";
      this.filtrate.notExpired = "";
      this.filtrate.type = null;
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/price/lease/list/byParkingLot", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          parkingLotId: this.parkingLotId,
          notExpired:
            this.filtrate.notExpired >= 0 ? this.filtrate.notExpired : null,
          seatGroupId: this.filtrate.seatGroupId
            ? this.filtrate.seatGroupId
            : null,
          priceId: this.filtrate.priceId ? this.filtrate.priceId : null,
          type: this.filtrate.type ? this.filtrate.type : null,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.leaseTimesFormat = item.leaseTimes.map((item) => {
              return {
                format: `${this.$moment(item.startTime).format(
                  "yyyy-MM-DD HH:mm:ss"
                )} ~ ${
                  item.endTime
                    ? this.$moment(item.endTime).format("yyyy-MM-DD HH:mm:ss")
                    : ""
                }`,
                notExpired: this.$moment(item.endTime).isBefore(this.$moment()),
              };
            });
          }

          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#vehicleListTab {
  .table-container {
    .table-filtrate {
      ul {
        .taigang {
          font-size: 12px;
          color: hsla(26, 100%, 64%, 0.96);

          span {
            color: hsla(217, 48%, 15%, 0.96);
            margin-right: 4px;
          }
        }

        li {
          .addVehicle {
            background-color: #ff9747;
            border-color: #ff9747;

            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
